<template>
    <v-container fluid>
		<v-row justify="center" class="ma-0">
			
            <!-- zone liste des comptes -->
            <v-col cols="12" sm="4" class="pa-0">
				<template v-if="!affichagePortable">
                    <v-select class="mb-4" v-if="acces_autorise(['super Admin'])" :items="listeCentres" label="Centre" prepend-inner-icon="mdi-fire-truck" outlined dense hide-details item-text="nom" return-object v-model="centre_selectionne"></v-select>
                    <v-text-field label="Recherche" dense outlined clearable prepend-inner-icon="mdi-magnify" v-model="texte_recherche" hide-details></v-text-field>
                    <v-toolbar dark flat dense color="blue-grey lighten-1" class="mt-4">
                        <v-toolbar-title>Comptes</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-chip class="ma-2" color="blue-grey lighten-3">{{ liste_membres_filtree.length }}</v-chip>
                        <v-tooltip left v-if="acces_autorise('modification comptes')">
                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on" @click="openDialogCreerCompte = true"><v-icon>mdi-account-plus</v-icon></v-btn>
                            </template>
                            <span>Ajouter un compte</span>
                        </v-tooltip>
                    </v-toolbar>
                    <v-list v-if="liste_membres_filtree.length > 0" :class="acces_autorise(['super Admin']) ? 'liste_membres_admin' : 'liste_membres'" class="pb-0">
                        <template v-for="(membre, index) in liste_membres_filtree">
                            <v-list-item :key="membre.id" ripple @click="onSelectMembre(membre)" :class="estMembreSelectionne(membre)">
                                <v-list-item-avatar color="blue-grey lighten-3">
                                    <img v-if="membre.user.photo" :src="getPhoto(membre.user.photo)">
                                    <span v-else class="white--text text-h6 font-weight-light">{{premiereLettreMaj(membre.user.prenom)+premiereLettreMaj(membre.user.nom)}}</span>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ membre.user.nom + ' ' +  membre.user.prenom}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider v-if="index + 1 < liste_membres_filtree.length" :key="'divider'+index"></v-divider>
                        </template>
                    </v-list>
                    <div v-else class="pa-3 text-center">
                        <span class="text-subtitle-1 primary--text font-weight-light">(aucun compte dans le centre)</span>
                    </div>
                </template>
                <template v-else>
                    <v-select class="mb-4" v-if="acces_autorise(['super Admin'])" :items="listeCentres" label="Centre" prepend-inner-icon="mdi-fire-truck" outlined dense hide-details item-text="nom" return-object v-model="centre_selectionne"></v-select>
                    <v-row class="ma-0 mt-12" justify="center" v-if="!membreCourant && liste_membres_filtree.length > 0">
						<v-btn small outlined @click="openListeSPVDrawer = true">Sélectionner un SP</v-btn>
					</v-row>
                    <v-row v-else-if="liste_membres_filtree.length == 0" class="ma-0" justify="center">
                        <div class="text-subtitle-1 primary--text font-weight-light">(aucun compte dans le centre)</div>
                    </v-row>
                </template>
            </v-col>

            <!-- zone détail du membre -->
            <v-col cols="12" sm="7" offset-sm="1" class="pa-0">
                <v-slide-y-transition mode="out-in">
                    <v-row class="ma-0" v-if="membreCourant != null" :key="membreCourant.id">
                        <v-col cols="12" sm="10" class="pa-0 pt-2">
                            <v-row class="ma-0">
                                <v-spacer></v-spacer>
                                <v-menu bottom left v-if="acces_autorise('modification comptes')" class="ml-2">
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on" :loading="chargement_menu">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list dense>
                                        <v-list-item @click="activationcompte(false)" v-if="membreCourant.estActif && autorisation_verrouillage_compte">
                                            <v-list-item-title>Verrouiller le compte</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="activationcompte(true)" v-if="!membreCourant.estActif && autorisation_verrouillage_compte">
                                            <v-list-item-title>Déverrouiller le compte</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="onClickSupprMembre" v-if="id_membre_courant != membreCourant.id && autorisation_suppression_compte">
                                            <v-list-item-title>Supprimer</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="onClickUpdateCompte">
                                            <v-list-item-title>Modifier</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="onClickReinitPassword" v-if="autorisation_reinit_mdp">
                                            <v-list-item-title>Réinitialiser le mot de passe</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </v-row>
                            <v-row class="ma-0 mb-4" justify="center" align="center"  @click="onClickNomMembreDetail">
                                <v-col cols="auto" class="pa-0">
                                    <v-avatar color="blue-grey lighten-3" :size="affichagePortable ? 80 : 100">
                                        <img v-if="membreCourant.user.photo" :src="getPhoto(membreCourant.user.photo)">
                                        <span v-else :class="affichagePortable ? 'text-h4':'text-h3'" class="white--text font-weight-light">{{ premiereLettreMaj(membreCourant.user.prenom)+premiereLettreMaj(membreCourant.user.nom)}}</span>
                                    </v-avatar>
                                </v-col>
                                <v-col cols="auto" class="pa-0 pl-4">
                                    <v-row class="ma-0" align="center">
                                        <v-avatar class="mr-4" v-if="membreCourant.user.grade" tile color="blue-grey lighten-3" size="30">
                                            <img :src="getPhoto(membreCourant.user.grade.photo)">
                                        </v-avatar>
                                        <div :class="affichagePortable ? 'text-h6':'text-h5'" class="font-weight-light primary--text">{{membreCourant.user.prenom + ' '+ membreCourant.user.nom}}</div>
                                    </v-row>
                                    <v-row class="ma-0 mt-0" v-if="membreCourant.user.matricule" align="center">
                                        <div class="text-subtitle-1 primary--text pt-1">{{membreCourant.user.matricule}}</div>
                                        <v-tooltip right v-if="!membreCourant.estActif">
                                            <template v-slot:activator="{ on }">
                                                <v-icon color="primary" class="ml-2" dark v-on="on">mdi-lock</v-icon>
                                            </template>
                                            <span>Compte verrouillé</span>
                                        </v-tooltip>
                                        <v-chip label small :color="getStatut(membreCourant.statut).couleur" class="ml-2">{{getStatut(membreCourant.statut).nom}}</v-chip>
                                        <v-chip v-if="membreCourant.user.en_dispo" label small color="brown lighten-4" class="ml-2">En dispo</v-chip>
                                    </v-row>
                                    <v-row class="ma-0" v-if="membreCourant.equipe">
                                        <div class="text-body-2 orange--text">{{membreCourant.equipe.nom}}</div>
                                    </v-row>
                                    <v-row class="ma-0">
                                        <div class="text-body-22 primary--text">{{membreCourant.user.telephone ? membreCourant.user.telephone : ''}}</div>
                                    </v-row>
                                    <v-row class="ma-0">
                                        <div class="text-body-2 primary--text">{{membreCourant.user.email ? membreCourant.user.email : ''}}</div>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <!-- <v-divider></v-divider> -->

                            <v-card class="mx-auto mt-md-12" outlined>
                                <v-toolbar dense flat color="blue-grey lighten-5">
                                    <v-toolbar-title>Aptitudes</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn icon v-if="acces_autorise('modification comptes')" @click="onClickAptitudes">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-card-text>
                                    <template v-if="membreCourant.user.aptitudes.length > 0">
                                        <v-chip v-for="aptitude in membreCourant.user.aptitudes" :key="aptitude.id" label small color="deep-purple lighten-4" class="ml-2">{{aptitude.nom}}</v-chip>
                                    </template>
                                    <template v-else>
                                        <div class="primary--text font-weight-light font-italic">(Aucune aptitude)</div>
                                    </template>
                                </v-card-text>
                            </v-card>

                            <v-card class="mx-auto mt-3" outlined>
                                <v-toolbar dense flat color="blue-grey lighten-5">
                                    <v-toolbar-title>Rôle: 
                                        <v-chip v-if="membreCourant.roles.length > 0" label small color="amber lighten-4" class="ml-2 mb-1">{{membreCourant.roles[0].name}}</v-chip>
                                        <span v-else class="font-weight-light text-body-2 font-italic primary--text">(aucun rôle affecté)</span>
                                    </v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-btn v-if="autorisation_modif_roles_permission" icon @click="onClickRolesPermissions">
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-btn>
                                </v-toolbar>
                                <v-card-text class="pa-0" v-if="membreCourant.roles.length > 0">
                                    <v-row class="ma-0 zone_role">
                                        <v-col cols=12 md="6" class="pa-0">
                                            <template>
                                                <v-list subheader two-line>
                                                    <v-subheader class="amber--text text--darken-1">Permissions liées au rôle</v-subheader>
                                                    <template v-if="membreCourant.roles.find(role => role.name == 'Super admin')">    
                                                        <div class="pl-4 font-weight-light text-body-2 font-italic primary--text">(toutes permissions affectées)</div>
                                                    </template>
                                                    <template v-else>
                                                        <template v-for="(permission, index) in membreCourant.roles[0].permissions">
                                                            <v-list-item :key="permission.id" :value="permission.id">
                                                                <v-list-item-content class="py-0">
                                                                    <v-list-item-title v-text="permission.name"></v-list-item-title>
                                                                    <v-list-item-subtitle>{{permission.description}}</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider v-if="index + 1 < membreCourant.roles[0].permissions.length" :key="'divider_'+index"></v-divider>
                                                        </template>
                                                    </template>
                                                </v-list>
                                            </template>
                                        </v-col>
                                        <v-col cols="12" md="6" class="pa-0">
                                            <template>
                                                <v-list subheader two-line>
                                                    <v-subheader class="amber--text text--darken-1">Permissions supplémentaires</v-subheader>
                                                    <template v-if="membreCourant.permissions.length > 0">    
                                                        <template v-for="(permission, index) in membreCourant.permissions">
                                                            <v-list-item :key="permission.id" :value="permission.id">
                                                                <v-list-item-content class="py-0">
                                                                    <v-list-item-title v-text="permission.name"></v-list-item-title>
                                                                    <v-list-item-subtitle>{{permission.description}}</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                            <v-divider v-if="index + 1 < membreCourant.permissions.length" :key="'divider_'+index"></v-divider>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <div class="pl-4 font-weight-light text-body-2 font-italic primary--text">(aucune permissions supplémentaires)</div>
                                                    </template>
                                                </v-list>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-slide-y-transition>
            </v-col>
		</v-row>

        <!-- drawer liste SPV pour portable -->
        <v-navigation-drawer app v-model="openListeSPVDrawer" right width="290" v-if="affichagePortable">
            <v-row class="ma-0">
                <v-col class="pa-0">
                    <v-toolbar dark flat dense color="blue-grey lighten-1">
                        <v-btn small icon class="ml-0" @click="openListeSPVDrawer = !openListeSPVDrawer">
							<v-icon>mdi-arrow-right</v-icon>
						</v-btn>
                        <v-toolbar-title>Comptes</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-chip class="ma-2" color="blue-grey lighten-3">{{ liste_membres_filtree.length }}</v-chip>
                        <v-btn v-if="acces_autorise('modification comptes')" icon @click="openDialogCreerCompte = true"><v-icon>mdi-account-plus</v-icon></v-btn>
                    </v-toolbar>
					<v-text-field class="ma-1" label="Recherche" dense outlined clearable prepend-inner-icon="mdi-magnify" v-model="texte_recherche" hide-details></v-text-field>
                    <v-list dense v-if="liste_membres_filtree.length > 0" class="pb-0 liste_spv_comptes_portable">
                        <template v-for="(membre, index) in liste_membres_filtree">
                            <v-list-item :key="membre.id" ripple @click="onSelectMembre(membre)" :class="estMembreSelectionne(membre)">
                                <v-list-item-avatar size="25" color="blue-grey lighten-3">
                                    <img v-if="membre.user.photo" :src="getPhoto(membre.user.photo)">
                                    <span v-else class="white--text text-subtitle-2 font-weight-light">{{premiereLettreMaj(membre.user.prenom)+premiereLettreMaj(membre.user.nom)}}</span>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>{{ membre.user.nom + ' ' +  membre.user.prenom}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider v-if="index + 1 < liste_membres_filtree.length" :key="'divider'+index"></v-divider>
                        </template>
                    </v-list>
				</v-col>
            </v-row>
            <v-row class="ma-0">
                <v-col cols="12" class="pa-0">
                    
                </v-col>
            </v-row>
        </v-navigation-drawer>

        <!-- dialogue confirmation -->
        <v-dialog v-model="openDialogueConfirmation" max-width="290" persistent>
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>{{ dialogue_confirmation.titre }}</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">{{ dialogue_confirmation.texte }}</v-card-text>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="blue-grey darken-1" text @click="annuleConfirmation">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="validConfirmation" :loading="chargement_confirmation">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- dialogue edit coordonnees -->
        <v-dialog v-model="openDialogEditCompte" persistent max-width="500px">
            <input type="file" style="display: none" ref="fileInput" name="image" accept="image/*" @change="onFilePicked">   <!--on masque le input car c'est moche et Vuetify ne possede pas de composant "File upload"-->
            <v-card>
                <v-toolbar dark flat dense color="primary" flat>
                    <v-toolbar-title>
                        <v-card-title>Modifier le compte</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                
                 <v-card-text class="px-4 pb-0">
                    <v-form class="mt-3">
                        <v-row class="ma-0">
                            <v-col cols="3" class="pa-0">
                                <v-img :src="formulaireEditCompte.photo_preview" max-height="117" max-width="117" aspect-ratio="1" contain class="grey lighten-2">
                                    <v-row class="ma-0" align="center" style="height: 100%" justify="center">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon v-on="on" @click="onMajPhoto">
                                                    <v-icon>mdi-camera</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Modifier la photo</span>
                                        </v-tooltip>
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon v-on="on" @click="formulaireEditCompte.photo_preview = null">
                                                    <v-icon>mdi-delete</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Supprimer la photo</span>
                                        </v-tooltip>
                                    </v-row>
                                </v-img>
                            </v-col>
                            <v-col cols="9" class="pa-0 pl-3 mt-2">
                                <v-row class="ma-0">
                                    <v-text-field outlined dense label="Nom" type="text" v-model.trim="$v.formulaireEditCompte.nom.$model" :error-messages="erreursNom"></v-text-field>
                                </v-row>
                                <v-row class="ma-0">
                                    <v-text-field outlined dense label="Prénom" type="text" v-model.trim="$v.formulaireEditCompte.prenom.$model" :error-messages="erreursPrenom"></v-text-field>
                                </v-row>
                            </v-col>
                        </v-row>
                        
                        <v-row class="ma-0">
                            <v-col cols="6" class="pa-0">
                                <v-select outlined dense style="max-width: 200px" :items="grades" hide-details label="Grade" v-model="formulaireEditCompte.id_grade" item-value="id">
                                    <template slot="item" slot-scope="data">
                                        <v-avatar size="30" tile v-if="data.item.photo">
                                            <img :src="getPhoto(data.item.photo)">
                                        </v-avatar>
                                        <v-spacer></v-spacer>
                                        <span>{{ data.item.nom }}</span>
                                    </template>
                                    <template slot="selection" slot-scope="data">
                                        <v-avatar size="25" tile v-if="data.item.photo">
                                            <img :src="getPhoto(data.item.photo)">
                                        </v-avatar>
                                        <v-spacer></v-spacer>
                                        <span>{{ data.item.nom }}</span>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="6" class="pa-0">
                                <v-select outlined dense hide-details class="ml-3" :items="liste_equipes" label="Equipe" v-model="formulaireEditCompte.id_equipe" item-text="nom" item-value="id"></v-select>
                            </v-col>
                        </v-row>
                        <v-row class="ma-0 mt-4">
                            <!-- désactivation pour conformité RGPD -->
                            <v-text-field disabled outlined dense label="Téléphone" type="text" hide-details v-model.trim="formulaireEditCompte.telephone"></v-text-field>
                        </v-row>
                        <v-row class="ma-0 mt-4">
                            <!-- désactivation pour conformité RGPD -->
                            <v-text-field disabled outlined dense label="Mail" type="text" hide-details  v-model.trim="formulaireEditCompte.mail"></v-text-field>
                        </v-row>   
                        <v-row class="ma-0">
                            <v-col cols="6" class="pa-0">
                                <v-radio-group hide-details v-model="formulaireEditCompte.statut">
                                    <v-radio label="SPV" :value=0></v-radio>
                                    <v-radio label="SPP" :value=1></v-radio>
                                    <v-radio label="PATS" :value=2></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="6" class="pa-0">
                                <v-switch  v-model="formulaireEditCompte.enDispo" inset label="En dispo"></v-switch>
                            </v-col>
                        </v-row>    
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey darken-1" text @click="onClickAnnuleUpdateCompte">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="updateCompte" :loading="chargementEditCompte" :disabled="estFormulaireModifCompteInvalid">OK</v-btn>  
                </v-card-actions>
            </v-card> 
        </v-dialog>

        <!-- dialogue edit aptitudes -->
        <v-dialog v-model="openDialogEditAptitudes" persistent max-width="500px">
            <v-card>
                <v-toolbar dark flat dense color="primary" flat>
                    <v-toolbar-title>
                        <v-card-title>Modifier les aptitudes</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                
                <v-card-text class="px-0 pb-0">
                    <v-list-item-group v-model="liste_aptitudes_membre_courant" multiple active-class="deep-purple--text">
                        <template v-for="(aptitude, index) in liste_aptitudes">
                            <v-list-item :key="aptitude.id" :value="aptitude.id">
                                <template v-slot:default="{ active, toggle }">
                                    <v-list-item-action>
                                        <v-checkbox v-model="active" color="deep-purple"></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title v-text="aptitude.nom"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                            <v-divider v-if="index + 1 < liste_aptitudes.length" :key="'divider_'+index"></v-divider>
                        </template>
                    </v-list-item-group>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey darken-1" text @click="openDialogEditAptitudes = false">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="updateAptitudes" :loading="chargementEditAptitudes">OK</v-btn>  
                </v-card-actions>
            </v-card> 
        </v-dialog>

        <!-- dialogue edit roles permissions -->
        <v-dialog v-model="openDialogEditRolePermissions" persistent max-width="1100px">
            <v-card :height="!affichagePortable ? '490':''">
                <v-toolbar dark flat dense color="primary" flat>
                    <v-toolbar-title>
                        <v-card-title>Modifier les droits</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                
                <v-card-text class="px-0 pb-0">
                    <v-row class="ma-0">
                        <v-col cols="12" sm="2" class="pa-0">
                            <v-subheader class="deep-purple--text text--darken-1">Rôle</v-subheader>
                            <v-list-item-group v-model="liste_roles_membre_courant" active-class="deep-purple--text">
                                <template v-for="(role, index) in liste_roles">
                                    <v-list-item dense :key="role.id" :value="role.id">
                                        <v-list-item-content>
                                            <v-list-item-title v-text="role.name"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider v-if="index + 1 < liste_roles.length" :key="'divider_'+index"></v-divider>
                                </template>
                            </v-list-item-group>
                        </v-col>
                        <v-col cols="12" sm="5" class="pa-0 pl-3">
                            <v-list subheader two-line>
                                <v-subheader class="deep-purple--text text--darken-1">Permissions du rôle</v-subheader>
                                <template v-if="liste_permissions_membre_courant.length > 0">
                                    <div  class="liste_roles_permissions">    
                                    <template v-for="(permission, index) in liste_permissions_membre_courant">
                                        <v-list-item :key="permission.id" :value="permission.id">
                                            <v-list-item-content class="py-0">
                                                <v-list-item-title v-text="permission.name"></v-list-item-title>
                                                <div class="text-body-2 grey--text">{{permission.description}}</div>
                                                <!-- <v-list-item-subtitle>{{permission.description}}</v-list-item-subtitle> -->
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-divider class="pt-1" v-if="index + 1 <liste_permissions_membre_courant.length" :key="'divider_'+index"></v-divider>
                                    </template>
                                    </div>
                                </template>
                                <template v-else>
                                    <div class="pl-4 font-weight-light text-body-2 font-italic primary--text">{{ liste_roles_membre_courant == 2 ? '(toutes les permissions)':'(aucune permissions pour ce rôle)'}}</div>
                                </template>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="5" class="pa-0">
                            <v-subheader class="deep-purple--text text--darken-1">Permissions supplémentaires</v-subheader>
                            <template v-if="liste_permissions.length > 0">
                                <div  class="liste_roles_permissions">    
                                    <v-list-item-group v-model="liste_nouvelles_permissions" multiple active-class="deep-purple--text">
                                        <template v-for="(permission, index) in liste_permissions">
                                            <v-list-item :key="permission.id" :value="permission.id">
                                                <template v-slot:default="{ active, toggle }">
                                                    <v-list-item-action>
                                                        <v-checkbox v-model="active" color="deep-purple"></v-checkbox>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="permission.name"></v-list-item-title>
                                                        <div class="text-body-2 grey--text">{{permission.description}}</div>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                            <v-divider v-if="index + 1 < liste_permissions.length" :key="'divider_'+index"></v-divider>
                                        </template>
                                    </v-list-item-group>
                                </div>
                            </template>
                            <template v-else>
                                <div class="pl-4 font-weight-light text-body-2 font-italic primary--text">{{ liste_roles_membre_courant == 2 ? '(toutes les permissions)':'(aucune permission supplémentaire)'}}</div>
                            </template>
                            
                        </v-col>
                    </v-row>
                   
                </v-card-text>
                <v-card-actions class="mt-3">
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey darken-1" text @click="openDialogEditRolePermissions = false">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="updateRolesPermissions" :loading="chargementEditRolesPermissions">OK</v-btn>  
                </v-card-actions>
            </v-card> 
        </v-dialog>

        <!-- dialogue création compte -->
        <v-dialog v-model="openDialogCreerCompte" persistent max-width="500px">
            <input type="file" style="display: none" ref="fileInput" name="image" accept="image/*" @change="onFilePicked">   <!--on masque le input car c'est moche et Vuetify ne possede pas de composant "File upload"-->
            <v-card>
                <v-toolbar dark flat dense color="primary">
                    <v-toolbar-title>
                        <v-card-title>Créer un compte</v-card-title>
                    </v-toolbar-title>
                </v-toolbar>
                
                 <v-card-text class="px-4 pb-0">
                    <v-form class="mt-3">
                        <v-row class="ma-0">
                            <v-col cols="6" class="pa-0 pr-1">
                                <v-text-field label="Matricule" type="text" :loading="chargementMatricule" @input="onChangeMatricule" v-model.trim="$v.formulaireCreerCompte.matricule.$model" :error-messages="erreursMatricule" :disabled="!saisie_matricule"></v-text-field>
                            </v-col>
                            <v-col cols="6" class="pa-0 pl-3">
                                <v-fade-transition mode="out-in">
                                    <span :key="message_matricule">{{ message_matricule }}</span>
                                </v-fade-transition>
                            </v-col>
                        </v-row>

                        <v-expand-transition>
                            <div v-show="affichage_contenu_formulaire_creation">
                                <v-row class="ma-0">
                                    <v-col cols="3" class="pa-0">
                                        <v-img :src="formulaireCreerCompte.photo_preview" max-height="117" max-width="117" aspect-ratio="1" contain class="grey lighten-2">
                                            <v-row class="ma-0" align="center" style="height: 100%" justify="center">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn icon v-on="on" @click="onMajPhoto">
                                                            <v-icon>mdi-camera</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Modifier la photo</span>
                                                </v-tooltip>
                                            </v-row>
                                        </v-img>
                                    </v-col>
                                    <v-col cols="9" class="pa-0 pl-3 mt-2">
                                        <v-row class="ma-0">
                                            <v-text-field outlined dense label="Nom" type="text" v-model.trim="$v.formulaireCreerCompte.nom.$model" :error-messages="erreursNomCreer"></v-text-field>
                                        </v-row>
                                        <v-row class="ma-0">
                                            <v-text-field outlined dense label="Prénom" type="text" v-model.trim="$v.formulaireCreerCompte.prenom.$model" :error-messages="erreursPrenomCreer"></v-text-field>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                
                                <v-row class="ma-0">
                                    <v-col cols="6" class="pa-0">
                                        <v-select :items="grades" outlined dense style="max-width: 200px" hide-details label="Grade" v-model="formulaireCreerCompte.id_grade" item-value="id">
                                            <template slot="item" slot-scope="data">
                                                <v-avatar size="30" tile v-if="data.item.photo">
                                                    <img :src="getPhoto(data.item.photo)">
                                                </v-avatar>
                                                <v-spacer></v-spacer>
                                                <span>{{ data.item.nom }}</span>
                                            </template>
                                            <template slot="selection" slot-scope="data">
                                                <v-avatar size="25" tile v-if="data.item.photo">
                                                    <img :src="getPhoto(data.item.photo)">
                                                </v-avatar>
                                                <v-spacer></v-spacer>
                                                <span>{{ data.item.nom }}</span>
                                            </template>
                                        </v-select>
                                    </v-col>
                                    <v-col cols="6" class="pa-0">
                                        <v-select outlined dense hide-details class="ml-3" :items="liste_equipes" label="Equipe" v-model="formulaireCreerCompte.id_equipe" item-text="nom" item-value="id"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row class="ma-0 mt-3">
                                    <v-col cols="6" class="pa-0 pr-2">
                                        <v-select outlined dense hide-details :items="liste_roles" label="Rôle" v-model="$v.formulaireCreerCompte.id_role.$model" item-text="name" item-value="id"></v-select>
                                    </v-col>
                                    <!-- désactivation pour conformité RGPD -->
                                    <!-- <v-col cols="6" class="pa-0 pl-2">
                                        <v-text-field outlined dense hide-details label="Téléphone" type="text" v-model.trim="formulaireCreerCompte.telephone"></v-text-field>
                                    </v-col> -->
                                </v-row>
                                <!-- désactivation pour conformité RGPD -->
                                <!-- <v-row class="ma-0 mt-3">
                                    <v-text-field outlined dense hide-details label="Mail" type="text" v-model.trim="formulaireCreerCompte.mail"></v-text-field>
                                </v-row>    -->
                                <v-row class="ma-0 mt-3">
                                    <v-col cols="4" class="pa-0">
                                        <v-radio-group hide-details v-model="formulaireCreerCompte.statut">
                                            <v-radio label="SPV" :value=0></v-radio>
                                            <v-radio label="SPP" :value=1></v-radio>
                                            <v-radio label="PATS" :value=2></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col cols="4" class="pa-0">
                                        <v-switch  v-model="formulaireCreerCompte.enDispo" inset label="En dispo"></v-switch>
                                    </v-col>
                                    <v-col cols="4" class="pa-0">
                                        <v-switch class="mt-2" v-if="affichage_activation_compte"  v-model="formulaireCreerCompte.activation_compte" inset label="Activer le compte"></v-switch>
                                    </v-col>
                                </v-row>    
                            </div>
					    </v-expand-transition>

                        
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey darken-1" text @click="onClickAnnuleCreerCompte">Annuler</v-btn>
                    <v-btn color="blue-grey darken-1" text @click="creerCompte" :loading="chargementCreerCompte" :disabled="estFormulaireCreerCompteInvalid">OK</v-btn>  
                </v-card-actions>
            </v-card> 
        </v-dialog>
	</v-container>
</template>

<script>
    import axios from 'axios'
    import { required, numeric, minLength, maxLength } from 'vuelidate/lib/validators'
    export default {
        created() {
            this.$store.commit('majTitrePage', 'Comptes utilisateur')
        
        },
        mounted() {
            //	initialisation du centre
            this.$store.dispatch('getListeCentres').then( () => {
				//	initialisation du centre
				this.centre_selectionne = this.$store.getters.membreCourant.centre
                this.$store.dispatch('getAnnuaire', this.centre_selectionne.id)
                this.$store.dispatch('getGrades')
                this.$store.dispatch('getAptitudes')
                this.$store.dispatch('getRoles', this.$store.getters.membreCourant.id)
                this.$store.dispatch('getPermissions', this.$store.getters.membreCourant.id)
			})
			
         
        },
        data() {
			return {
                affichage_activation_compte: true,
                affichage_contenu_formulaire_creation: false,
                centre_selectionne: null,
                chargement_confirmation: false,
                chargementEditAptitudes: false,
                chargementEditCompte: false,
                chargementCreerCompte: false,
                chargementEditRolesPermissions: false,
                chargementMatricule: false,
                chargement_menu: false,
                dialogue_confirmation: {
                    titre: '',
                    texte: '',
                    action: ''
                },
                fichier_photo: null,
                formulaireCreerCompte: {
                    id_membre_modifieur: null,
                    telephone: null,
                    mail: null,
                    id_equipe: null,
                    id_grade: null,
                    nom: '',
                    prenom: '',
                    photo_preview: '',
                    statut: 0,
                    enDispo: false,
                    matricule: null,
                    id_role: null,
                    id_membre: null,
                    id_user: null,
                    code_creation: null,
                    activation_compte: false,
                    id_centre: null
                },
                formulaireEditCompte: {
                    id_membre_modifieur: null,
                    id_membre: null,
                    telephone: null,
                    mail: null,
                    id_equipe: null,
                    id_grade: null,
                    nom: '',
                    prenom: '',
                    photo_preview: null,
                    statut: 0,
                    enDispo: false
                },
                liste_aptitudes_membre_courant: [],
                liste_nouvelles_permissions: [],
                liste_roles_membre_courant: [],
                membreCourant: null,
                message_matricule: '',
                openDialogueConfirmation: false,
                openDialogCreerCompte: false,
                openDialogEditAptitudes: false,
                openDialogEditCompte: false,
                openDialogEditRolePermissions: false,
                openListeSPVDrawer: false,
                saisie_matricule: true,
                texte_recherche: ''
                
            }
		},
		computed: {
            affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
            },
            autorisation_modif_roles_permission() {
                // 1 super admin ne peut pas modifier son propre rôle
                if (this.estMembreCourantSuperAdmin && this.membreCourant.id == this.$store.getters.membreCourant.id ) return false 
                
                // 1 non super admin ne peut pas modifier un super admin
                if (!this.estMembreCourantSuperAdmin && this.membreCourant.roles.find(role => role.name == 'Super admin') ) return false

                //  sinon on regarde si l'utilisateur a l'autorisation de modifier les roles et permissions
                return this.acces_autorise('modifications roles et permissions')
            },
            autorisation_reinit_mdp() {
                // 1 non super admin ne peut pas reinitialiser le mdp d'un super admin
                if (!this.estMembreCourantSuperAdmin && this.membreCourant.roles.find(role => role.name == 'Super admin') ) return false

                return true
            },
            autorisation_suppression_compte() {
                // 1 non super admin ne peut pas supprimer un compte super admin
                if (!this.estMembreCourantSuperAdmin && this.membreCourant.roles.find(role => role.name == 'Super admin') ) return false

                return true
            },
            autorisation_verrouillage_compte() {
                // 1 utilisateur ne peut pas verrouiller son propre compte
                if(this.membreCourant.id == this.$store.getters.membreCourant.id ) return false

                //  1 non super admin ne peut pas verrouiller un super admin
                if (!this.estMembreCourantSuperAdmin && this.membreCourant.roles.find(role => role.name == 'Super admin') ) return false

                return true
            },
            centre_courant() {
				return this.$store.getters.membreCourant.centre
            },
            erreursMatricule() {
                const errors = []
                if (!this.$v.formulaireCreerCompte.matricule.$dirty) return errors
                !this.$v.formulaireCreerCompte.matricule.required && errors.push('Le matricule est obligatoire')
                if(!this.$v.formulaireCreerCompte.matricule.numeric) errors.push('Le matricule ne doit comporter que des chiffres')
                if(!this.$v.formulaireCreerCompte.matricule.minLength || !this.$v.formulaireCreerCompte.matricule.maxLength) errors.push('Le matricule doit comporter 6 chiffres')
                
                return errors
            },
            erreursNom() {
                const errors = []
                if (!this.$v.formulaireEditCompte.nom.$dirty) return errors
                !this.$v.formulaireEditCompte.nom.required && errors.push('Le nom est obligatoire')
                return errors
            },
            erreursNomCreer() {
                const errors = []
                if (!this.$v.formulaireCreerCompte.nom.$dirty) return errors
                !this.$v.formulaireCreerCompte.nom.required && errors.push('Le nom est obligatoire')
                return errors
            },
            erreursPrenom() {
                const errors = []
                if (!this.$v.formulaireEditCompte.prenom.$dirty) return errors
                !this.$v.formulaireEditCompte.prenom.required && errors.push('Le prénom est obligatoire')
                return errors
            },
            erreursPrenomCreer() {
                const errors = []
                if (!this.$v.formulaireCreerCompte.prenom.$dirty) return errors
                !this.$v.formulaireCreerCompte.prenom.required && errors.push('Le prénom est obligatoire')
                return errors
            },
            estFormulaireCreerCompteInvalid() {
                return this.$v.formulaireCreerCompte.$invalid
            },
            estFormulaireModifCompteInvalid() {
                return this.$v.formulaireEditCompte.$invalid
            },
            estMembreCourantSuperAdmin() {
                const roles_utilisateur = this.$store.getters.membreCourant.roles
                return roles_utilisateur.find(role => role.name == 'Super admin')
            },
            grades() {
                return this.$store.getters.grades
            },
            id_membre_courant() {
                return this.$store.getters.membreCourant.id
            },
            liste_aptitudes() {
                return this.$store.getters.aptitudes
            },
            listeCentres() {
				return this.$store.getters.liste_centres
			},
            liste_equipes() {
                let liste = [{id: null, nom: 'Aucune'}]
                if(!this.centre_selectionne) return []
                this.centre_selectionne.equipes.forEach(equipe => {
                    const type = equipe.est_spp ? ' (SPP)' : ' (SPV)'
                    liste.push({id: 
                        equipe.id, 
                        nom: equipe.nom + type})
                })
                return liste
            },
			liste_membres() {
				return this.$store.getters.annuaire
			},
			liste_membres_filtree() {
				const liste_complete = this.$store.getters.annuaire
				if(this.texte_recherche){
                    return liste_complete.filter( element => {
                        return (
								element.user.nom.toLowerCase().indexOf(this.texte_recherche.toLowerCase()) > -1
                            || element.user.prenom.toLowerCase().indexOf(this.texte_recherche.toLowerCase()) > -1
                        )
                    })
                }
                return liste_complete
            },
            liste_permissions() {
                if(this.liste_roles_membre_courant == 2) return []   // si supermadmin sélectionné
                return this.$store.getters.permissions.filter( permission => {
                    return this.liste_permissions_membre_courant.find( perm => perm.id == permission.id) == undefined
                })
            },
            liste_permissions_membre_courant() {
                const roles = this.liste_roles.find( role => role.id == this.liste_roles_membre_courant)
                return roles != undefined ? roles.permissions : []
            },
            liste_permissions_supp_membre_courant() {
                return []
            },
            liste_roles() {
                return this.$store.getters.roles
            },
            
  
		},
		methods: {
            acces_autorise(permissions_requises) {
                // retourne true si l'utilisateur à les permissions listées
                
                let permission_trouvee = false

                if(permissions_requises.length > 0 && this.$store.getters.membreCourant) {
                
                    const roles_utilisateur = this.$store.getters.membreCourant.roles
                    const permissions_utilisateur = this.$store.getters.membreCourant.permissions

                    const estSuperAdmin = roles_utilisateur.find(role => role.name == 'Super admin')
                    

                    //	si super admin on autorise tout
                    if (estSuperAdmin) {
                        permission_trouvee = true
                    }
                    else {
                        //	on cherche si la permission demandée se trouve dans un des roles de l'utilisateur
                        roles_utilisateur.forEach(role => {
                            role.permissions.forEach(permission => {
                                    if( permissions_requises.includes(permission.name) ) {
                                        permission_trouvee = true
                                    }
                                })
                        })
                    

                        // si la permission n'a pas déjà été trouvée, on cherche si elle est dans les permissions directes
                        if (!permission_trouvee) {
                            
                            permissions_utilisateur.forEach(permission => {
                                if( permissions_requises.includes(permission.name) ) {
                                    permission_trouvee = true
                                }
                            })
                        }
                    }
                }

                //	on autorise si pas de permission ou si une permission et que la permission est trouvée
                return permissions_requises.length == 0 || (permissions_requises.length > 0 && permission_trouvee)
            },
            activationcompte(valeur) {
                this.chargement_menu = true
                axios.post('api/centre/membre/verrouillage', { id_membre_a_verrouiller: this.membreCourant.id, id_membre: this.$store.getters.membreCourant.id, activation: valeur})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
                        this.$store.dispatch('getAnnuaire', this.centre_selectionne.id)
                            .then( () => this.membreCourant = this.liste_membres_filtree.find( membre => membre.id == this.membreCourant.id))
                    })
                    .catch((error) => {
                        this.snackbar('error', error.response.data.error);
                    })
                    .then(() => {
                        this.chargement_menu = false
                    })
            },
            annuleConfirmation() {
                this.dialogue_confirmation.titre = ''
                this.dialogue_confirmation.texte = ''
                this.dialogue_confirmation.action = ''
                this.openDialogueConfirmation = false
            },
            creerCompte() {
                //  on vérifie que le formulaire est valide
                if( !this.$v.formulaireCreerCompte.$invalid) {
                    this.chargementCreerCompte = true
                    this.formData = new FormData;
                    // si présence d'une nouvelle photo (fichier_photo non null)
                    if (this.fichier_photo) {
                        this.formData.append('photo',this.fichier_photo);
                    }
                    this.formData.append('donnees', JSON.stringify(this.formulaireCreerCompte));
                    axios.post('/api/centre/membre/creer', this.formData,{headers: {'Content-Type': 'multipart/form-data'}})
                        .then((reponse) => {
                            this.snackbar('success',reponse.data.resultat);
                            this.$store.dispatch('getAnnuaire', this.centre_selectionne.id)
                                .then( () => this.membreCourant = this.liste_membres_filtree.find( membre => membre.id == reponse.data.membre_id))
                        })
                        .catch((error) => {
                            this.snackbar('error','Erreur lors de la création du compte');
                        })
                        .then ( () => {
                            this.chargementCreerCompte = false;
                            this.onClickAnnuleCreerCompte()
                        })
                }
            },
            estMembreSelectionne(membre) {
                let est_selectionne = (this.membreCourant != null && this.membreCourant.id == membre.id) ? true : false
                const inactif = membre.user.en_dispo || !membre.estActif
                return est_selectionne ? 'membreSelectionne' : (inactif ? 'membreInactif': '')
            },
            getPhoto(chemin){
				return "/storage/"+chemin;
            },
            getStatut(statut) {
                // renvoie le nom dustatut et la couleur à utiliser
                // 0: SPV
                // 1: SPP
                // 2: PATS
                let nom = ''
                let couleur = ''

                switch (statut) {
                    case 0:
                        nom = 'SPV'
                        couleur = 'light-green lighten-4'
                        break;
                    case 1:
                        nom = 'SPP'
                        couleur = 'blue lighten-4'
                        break;
                    case 2:
                        nom = 'PATS'
                        couleur = 'lime lighten-4'
                        break;
                    default:
                        break;
                }

                return {
                    nom: nom,
                    couleur: couleur
                }
            },
            onClickAnnuleCreerCompte() {
                this.openDialogCreerCompte = false 
                this.formulaireCreerCompte.matricule = null
                this.formulaireCreerCompte.id_role = null
                this.formulaireCreerCompte.id_centre = null
                this.formulaireCreerCompte.telephone = null
                this.formulaireCreerCompte.mail = null    
                this.formulaireCreerCompte.id_equipe = null
                this.formulaireCreerCompte.id_grade = null
                this.formulaireCreerCompte.id_membre_modifieur = null
                this.formulaireCreerCompte.nom = ''    
                this.formulaireCreerCompte.prenom = ''
                this.formulaireCreerCompte.statut = 0
                this.formulaireCreerCompte.enDispo = false
                this.formulaireCreerCompte.photo_preview = ''
                this.formulaireCreerCompte.id_membre = null
                this.formulaireCreerCompte.id_user = null
                this.formulaireCreerCompte.code_creation = null
                this.formulaireCreerCompte.activation_compte = false
                this.fichier_photo = null
                this.chargementMatricule = false
                this.saisie_matricule = true
                this.affichage_contenu_formulaire_creation = false
                this.affichage_activation_compte = true
                this.message_matricule = ''
                this.$v.formulaireCreerCompte.$reset()
            },
            onClickAnnuleUpdateCompte() {
                this.openDialogEditCompte = false 
                this.formulaireEditCompte.telephone = null
                this.formulaireEditCompte.mail = null    
                this.formulaireEditCompte.id_equipe = null
                this.formulaireEditCompte.id_grade = null
                this.formulaireEditCompte.id_membre_modifieur = null
                this.formulaireEditCompte.id_membre = null    
                this.formulaireEditCompte.nom = ''    
                this.formulaireEditCompte.prenom = ''
                this.formulaireEditCompte.statut = 0
                this.formulaireEditCompte.enDispo = false
                this.formulaireEditCompte.photo_preview = ''
                this.fichier_photo = null
                   
            },
            onClickAptitudes() {
                let liste = []
                this.membreCourant.user.aptitudes.forEach(aptitude => {
                    liste.push(aptitude.id)
                })
                this.liste_aptitudes_membre_courant = liste
                this.openDialogEditAptitudes = true
            },
            onClickNomMembreDetail() {
				//	on ouvre le drawer si en mode affichage portable
				if(this.affichagePortable) this.openListeSPVDrawer = true
			},
            onClickReinitPassword() {
                this.dialogue_confirmation.titre = 'Réinitialisation'
				this.dialogue_confirmation.texte = 'Réinitialiser le mot de passe de '+this.membreCourant.user.prenom + ' ' + this.membreCourant.user.nom +' à "'+this.membreCourant.user.matricule+'" ?'
				this.dialogue_confirmation.action = 'reinit_password'
				this.openDialogueConfirmation = true
            },
            onClickRolesPermissions() {
                let liste_roles = []
                let liste_perm_supp = []
                this.membreCourant.roles.forEach( role => {
                    liste_roles.push(role.id)
                })
                this.liste_roles_membre_courant = liste_roles[0]

                this.membreCourant.permissions.forEach( perm => {
                    liste_perm_supp.push(perm.id)
                })
                this.liste_nouvelles_permissions = liste_perm_supp

                this.openDialogEditRolePermissions = true
            },
            onClickSupprMembre() {
				this.dialogue_confirmation.titre = 'Suppression'
				this.dialogue_confirmation.texte = 'Supprimer le compte de '+this.membreCourant.user.prenom + ' ' + this.membreCourant.user.nom +' ?'
				this.dialogue_confirmation.action = 'suppr_compte'
				this.openDialogueConfirmation = true
            },
            onClickUpdateCompte() {
                this.formulaireEditCompte.telephone = this.membreCourant.user.telephone
                this.formulaireEditCompte.mail = this.membreCourant.user.email    
                this.formulaireEditCompte.id_equipe = this.membreCourant.id_equipe
                this.formulaireEditCompte.id_grade = this.membreCourant.user.id_grade    
                this.formulaireEditCompte.nom = this.membreCourant.user.nom    
                this.formulaireEditCompte.prenom = this.membreCourant.user.prenom
                this.formulaireEditCompte.statut = this.membreCourant.statut
                this.formulaireEditCompte.enDispo = this.membreCourant.user.en_dispo
                this.formulaireEditCompte.photo_preview = this.membreCourant.user.photo ? '/storage/'+this.membreCourant.user.photo : ''
                this.formulaireEditCompte.id_membre = this.membreCourant.id
                this.formulaireEditCompte.id_membre_modifieur = this.$store.getters.membreCourant.id
                this.openDialogEditCompte = true
            },
            onFilePicked(event){
                const fichiers = event.target.files;     //ici 1 seul fichier car pas de multiselect dans le choix de l'image
                let nomFichier = fichiers[0].name;   // on prend le 1er fichier du tableau
                
                const fileReader = new FileReader();
                fileReader.addEventListener('load', ()=>{   // quand l'image sera chargée on l'affiche
                    this.formulaireEditCompte.photo_preview = fileReader.result;
                    this.formulaireCreerCompte.photo_preview = fileReader.result;
                })
                fileReader.readAsDataURL(fichiers[0]);
                this.fichier_photo = fichiers[0];        // le contenu binaire de l'image est chargée pour envoi du formulaire
            },
            onChangeMatricule() {
                if(this.formulaireCreerCompte.matricule.length == 6 && !this.$v.formulaireCreerCompte.matricule.$invalid) {
                    this.saisie_matricule = false
                    this.chargementMatricule = true

                    axios.post('api/centre/membre/verif_matricule', { 
                        matricule: this.formulaireCreerCompte.matricule, 
                        id_membre: this.$store.getters.membreCourant.id,
                        id_centre: this.centre_selectionne.id
                        })
                        .then((reponse) => {
                            //  si utilisateur et membre déjà existant et actif dans le centre
                            if(reponse.data.code == 2) {
                                this.affichage_contenu_formulaire_creation = false
                                this.affichage_activation_compte = false
                            }
                            //  si utilisateur non existant ou n'ayant pas existé
                            else if(reponse.data.code == 0) {
                                this.affichage_contenu_formulaire_creation = true
                            }
                            else if(reponse.data.code == 1 || reponse.data.code == 3 || reponse.data.code == 4 || reponse.data.code == 5) {
                                //  remplissage du formulaire creation avec les donnees du user existant
                                this.formulaireCreerCompte.telephone = reponse.data.user.telephone,
                                this.formulaireCreerCompte.mail = reponse.data.user.email
                                this.formulaireCreerCompte.id_grade = reponse.data.user.id_grade
                                this.formulaireCreerCompte.nom = reponse.data.user.nom
                                this.formulaireCreerCompte.prenom = reponse.data.user.prenom
                                this.formulaireCreerCompte.photo_preview = reponse.data.user.photo ? '/storage/'+reponse.data.user.photo : ''
                                this.formulaireCreerCompte.statut = 0
                                this.formulaireCreerCompte.enDispo = reponse.data.user.en_dispo
                                this.formulaireCreerCompte.id_user = reponse.data.user.id
                                this.formulaireCreerCompte.id_membre = reponse.data.user.membres_centre.length > 0 ? reponse.data.user.membres_centre[0].id : null

                                //  si un membre existant est trouvé
                                if(reponse.data.user.membres_centre.length > 0) {
                                    this.formulaireCreerCompte.id_equipe = reponse.data.user.membres_centre[0].id_equipe
                                    this.formulaireCreerCompte.id_role = reponse.data.user.membres_centre[0].roles.length > 0 ? reponse.data.user.membres_centre[0].roles[0].id : null
                                }
                                
                                this.affichage_contenu_formulaire_creation = true
                            }
                            
                            // this.snackbar('success',reponse.data.resultat)
                            this.message_matricule = reponse.data.resultat
                            this.formulaireCreerCompte.code_creation = reponse.data.code
                            this.formulaireCreerCompte.id_membre_modifieur = this.$store.getters.membreCourant.id
                            this.formulaireCreerCompte.id_centre = this.centre_selectionne.id
                            
                        })
                        .catch((error) => {
                            this.snackbar('error', 'Erreur lors de la vérification du matricule');
                        })
                        .then(() => {
                            this.saisie_matricule = true
                            this.chargementMatricule = false
                    	})

                }
                else{
                    this.formulaireCreerCompte.id_role = null
                    this.formulaireCreerCompte.telephone = null
                    this.formulaireCreerCompte.id_centre = null
                    this.formulaireCreerCompte.mail = null    
                    this.formulaireCreerCompte.id_equipe = null
                    this.formulaireCreerCompte.id_grade = null
                    this.formulaireCreerCompte.nom = ''    
                    this.formulaireCreerCompte.prenom = ''
                    this.formulaireCreerCompte.statut = 0
                    this.formulaireCreerCompte.enDispo = false
                    this.formulaireCreerCompte.photo_preview = ''
                    this.fichier_photo = null
                    this.formulaireCreerCompte.id_membre = null
                    this.formulaireCreerCompte.id_user = null
                    this.formulaireCreerCompte.code_creation = null
                    this.formulaireCreerCompte.activation_compte = false
                    this.affichage_activation_compte = true
                    this.message_matricule = ''
                }
            },
            onMajPhoto() {
                // on active le click sur le input de type file
                this.$refs.fileInput.click();
            },
            onSelectMembre(membre) {
                this.membreCourant = membre
                this.openListeSPVDrawer = false		//	cas d'un affichage portable
                
            },
			premiereLettreMaj(text){
				return text.charAt(0).toUpperCase();
			},
            snackbar(type,message){
                this.$store.commit('afficheSnackbar', {type: type, message: message});
            },
            updateAptitudes() {
                this.chargementEditAptitudes = true
                axios.post('api/centre/membre/update_aptitudes', { id_membre_a_modifier: this.membreCourant.id, id_membre: this.$store.getters.membreCourant.id, aptitudes: this.liste_aptitudes_membre_courant})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
                        this.$store.dispatch('getAnnuaire', this.centre_selectionne.id)
                            .then( () => this.membreCourant = this.liste_membres_filtree.find( membre => membre.id == this.membreCourant.id))
                        })
                        .catch((error) => {
                            this.snackbar('error', 'Erreur lors de la modification des aptitudes');
                        })
                        .then(() => {
                            this.chargementEditAptitudes = false
                            this.openDialogEditAptitudes = false
                    	})
            },
            updateCompte() {
                //  on vérifie que le formulaire est valide
                if( !this.$v.formulaireEditCompte.$invalid) {
                    this.chargementEditCompte = true
                    this.formData = new FormData;
                    // si présence d'une nouvelle photo (fichier_photo non null)
                    if (this.fichier_photo) {
                        this.formData.append('photo',this.fichier_photo);
                    }
                    this.formData.append('donnees', JSON.stringify(this.formulaireEditCompte));
                    axios.post('/api/centre/membre/update', this.formData,{headers: {'Content-Type': 'multipart/form-data'}})
                        .then((reponse) => {
                            this.snackbar('success',reponse.data.resultat);
                            this.$store.dispatch('getAnnuaire', this.centre_selectionne.id)
                                .then( () => this.membreCourant = this.liste_membres_filtree.find( membre => membre.id == this.membreCourant.id))
                        })
                        .catch((error) => {
                            this.snackbar('error','Erreur lors de la mise à jour du compte');
                        })
                        .then ( () => {
                            this.chargementEditCompte = false;
                            this.onClickAnnuleUpdateCompte()
                        })
                
                
                
                }
            },
            updateRolesPermissions() {
                this.chargementEditRolesPermissions = true
                axios.post('api/centre/membre/update_roles_perm', { id_membre_a_modifier: this.membreCourant.id, id_membre: this.$store.getters.membreCourant.id, role: this.liste_roles_membre_courant, permissions: this.liste_nouvelles_permissions})
                    .then((reponse) => {
                        this.snackbar('success',reponse.data.resultat);
                        this.$store.dispatch('getAnnuaire', this.centre_selectionne.id)
                            .then( () => this.membreCourant = this.liste_membres_filtree.find( membre => membre.id == this.membreCourant.id))
                        })
                        .catch((error) => {
                            this.snackbar('error', 'Erreur lors de la modification des rôles et permissions');
                        })
                        .then(() => {
                            this.chargementEditRolesPermissions = false
                            this.openDialogEditRolePermissions = false
                    	})
            },
            validConfirmation() {
                switch (this.dialogue_confirmation.action) {
                    case 'suppr_compte':
                    	this.chargement_confirmation = true
                    	axios.post('api/centre/membre/suppr', { id_membre_a_suppr: this.membreCourant.id, id_membre: this.$store.getters.membreCourant.id})
                            .then((reponse) => {
                                this.snackbar('success',reponse.data.resultat);
                                this.$store.dispatch('getAnnuaire', this.centre_selectionne.id)
                                this.membreCourant = null
                            })
                            .catch((error) => {
                                this.snackbar('error', error.response.data.error);
                            })
                            .then(() => {
                                this.chargement_confirmation = false
                                this.annuleConfirmation()
                    		})
                        break;
                    
                    case 'reinit_password':
                    	this.chargement_confirmation = true
                    	axios.post('api/centre/membre/reinit_password', { id_membre_a_reinit: this.membreCourant.id, id_membre: this.$store.getters.membreCourant.id})
                            .then((reponse) => {
                                this.snackbar('success',reponse.data.resultat);
                            })
                            .catch((error) => {
                                this.snackbar('error', 'Erreur lors de la réinitialisation du mot de passe');
                            })
                            .then(() => {
                                this.chargement_confirmation = false
                                this.annuleConfirmation()
                    		})
                    	break;
                   
                    default:
                        break;
                }
			},

        },
        validations: {
            formulaireEditCompte: {
                nom: {
                    required,                
                },
                prenom: {
                    required,           
                },
            },
            formulaireCreerCompte: {
                id_role: {
                    required,                
                },
                matricule: {
                    required,
                    numeric,
                    minLength: minLength(6),
                    maxLength: maxLength(6)                
                },
                nom: {
                    required,                
                },
                prenom: {
                    required,           
                },    
            }
        },
        watch: {
			centre_courant() {
				
                //  si pas autorisé à rester sur cette page on le vire
                if(!this.acces_autorise('acces gestion comptes')) {
                    this.$router.push('/')
                }
                else {
                    // this.$store.dispatch('getAnnuaire', this.$store.getters.membreCourant.centre.id)
                    // this.membreCourant = null
                    this.centre_selectionne = this.$store.getters.membreCourant.centre

                }

            },
            centre_selectionne() {
                this.membreCourant = null
                this.$store.dispatch('getAnnuaire', this.centre_selectionne.id)
				

			}
		}
  	}
</script>

<style>


</style>

<style scoped>
.liste_membres {
    max-height: calc(100vh - 220px);
    /* min-height: 290px; */
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
    scrollbar-width: thin;
}

.liste_membres_admin {
    max-height: calc(100vh - 275px);
    /* min-height: 290px; */
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
    scrollbar-width: thin;
}

.liste_membres_portable {
    max-height: calc(60vh - 290px);
    /* min-height: 290px; */
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
}

.liste_roles_permissions {
    max-height: 320px;
    /* min-height: 290px; */
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
}

.liste_spv_comptes_portable {
    max-height: calc(100vh - 98px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0;
}

.membreInactif{
    background-color:rgb(241, 241, 241);
}

.membreSelectionne {
    background-color:#E8F5E9;
}

.zone_role {
    max-height: calc(100vh - 600px);
    overflow-y: auto;
    overflow-x: hidden;
}
</style>

